export default {
  name: "resetPassword",
  data() {
    return {
      email: this.$store.getters["forgotPassword/email"],
      code: "",
      newPassword: "",
      confirmNewPassword: "",
      error: ""
    };
  },
  beforeCreate() {
    this.email = this.$store.getters["forgotPassword/email"];
    if (!this.email) {
      this.$router.push("login");
    }
  },
  methods: {
    resetPassword() {
      this.$store.dispatch("auth/forgotPasswordSubmit", {
        email: this.email,
        code: this.code,
        newPassword: this.newPassword
      });
    }
  }
};
